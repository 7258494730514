import $ from 'jquery';

$('#sort-by').on('change', function() {
    const $list = $($(this).data().target);
    const selected = $('#sort-by :selected').val();

    const elems = $list.children().detach().sort(function (a, b) {
        let aVal = $(a).data()[selected];
        let bVal = $(b).data()[selected];

        if (!isNaN(aVal)) {
            aVal = parseFloat(aVal);
            bVal = parseFloat(bVal);
        }

        if (aVal < bVal)  return -1;
        if (aVal == bVal) return 0;
        if (aVal > bVal)  return 1;
    });

    $list.append(elems);
});
